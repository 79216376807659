exports.components = {
  "component---src-dynamic-pages-blog-page-index-tsx": () => import("./../../../src/dynamic-pages/blog-page/index.tsx" /* webpackChunkName: "component---src-dynamic-pages-blog-page-index-tsx" */),
  "component---src-dynamic-pages-blog-post-index-tsx": () => import("./../../../src/dynamic-pages/blog-post/index.tsx" /* webpackChunkName: "component---src-dynamic-pages-blog-post-index-tsx" */),
  "component---src-dynamic-pages-case-study-index-tsx": () => import("./../../../src/dynamic-pages/case-study/index.tsx" /* webpackChunkName: "component---src-dynamic-pages-case-study-index-tsx" */),
  "component---src-dynamic-pages-looking-for-developer-index-tsx": () => import("./../../../src/dynamic-pages/looking-for-developer/index.tsx" /* webpackChunkName: "component---src-dynamic-pages-looking-for-developer-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-how-we-work-tsx": () => import("./../../../src/pages/about-us/how-we-work.tsx" /* webpackChunkName: "component---src-pages-about-us-how-we-work-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-about-us-origins-tsx": () => import("./../../../src/pages/about-us/origins.tsx" /* webpackChunkName: "component---src-pages-about-us-origins-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-request-tsx": () => import("./../../../src/pages/request.tsx" /* webpackChunkName: "component---src-pages-request-tsx" */),
  "component---src-pages-services-artificial-intelligence-tsx": () => import("./../../../src/pages/services/artificial-intelligence.tsx" /* webpackChunkName: "component---src-pages-services-artificial-intelligence-tsx" */),
  "component---src-pages-services-dedicated-team-tsx": () => import("./../../../src/pages/services/dedicated-team.tsx" /* webpackChunkName: "component---src-pages-services-dedicated-team-tsx" */),
  "component---src-pages-services-design-tsx": () => import("./../../../src/pages/services/design.tsx" /* webpackChunkName: "component---src-pages-services-design-tsx" */),
  "component---src-pages-services-development-tsx": () => import("./../../../src/pages/services/development.tsx" /* webpackChunkName: "component---src-pages-services-development-tsx" */),
  "component---src-pages-services-devops-tsx": () => import("./../../../src/pages/services/devops.tsx" /* webpackChunkName: "component---src-pages-services-devops-tsx" */),
  "component---src-pages-services-fixed-price-tsx": () => import("./../../../src/pages/services/fixed-price.tsx" /* webpackChunkName: "component---src-pages-services-fixed-price-tsx" */),
  "component---src-pages-services-integrations-tsx": () => import("./../../../src/pages/services/integrations.tsx" /* webpackChunkName: "component---src-pages-services-integrations-tsx" */),
  "component---src-pages-services-iot-tsx": () => import("./../../../src/pages/services/iot.tsx" /* webpackChunkName: "component---src-pages-services-iot-tsx" */),
  "component---src-pages-services-location-and-asset-tracking-tsx": () => import("./../../../src/pages/services/location-and-asset-tracking.tsx" /* webpackChunkName: "component---src-pages-services-location-and-asset-tracking-tsx" */),
  "component---src-pages-services-staff-augmentation-tsx": () => import("./../../../src/pages/services/staff-augmentation.tsx" /* webpackChunkName: "component---src-pages-services-staff-augmentation-tsx" */),
  "component---src-pages-services-testing-tsx": () => import("./../../../src/pages/services/testing.tsx" /* webpackChunkName: "component---src-pages-services-testing-tsx" */),
  "component---src-pages-services-time-and-material-tsx": () => import("./../../../src/pages/services/time-and-material.tsx" /* webpackChunkName: "component---src-pages-services-time-and-material-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */)
}

